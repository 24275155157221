import React from "react";
import './components/styles.scss';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './pages/Home';
import Music from './pages/Music';
import Videos from './pages/Videos';
import StagePlays from './pages/StagePlays';
import Books from './pages/Books';
import Poetry from './pages/Poetry';
import Artwork from './pages/Artwork';
import Blog from './pages/Blog';
import About from './pages/About';
import Shop from './pages/Shop';
import Samples from './pages/Samples';
import Musicals from './pages/Musicals';
import Meditate from './pages/Meditate';
import { Routes, useLocation, Route } from 'react-router-dom';


function App() {


const location=useLocation()
const isDjsetsRendering = location.pathname==="/djsets"
const isVideosRendering = location.pathname==="/videos"

  return (
        <div>
          <Navbar/>          
          <div>          
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/meditate" element={<Meditate />} />
              <Route path="/music" element={<Music />} />
              <Route path="/videos" element={<Videos />} />
              <Route path="/stageplays" element={<StagePlays />} />
              <Route path="/musicals" element={<Musicals />} /> 
              <Route path="/books" element={<Books />} />
              <Route path="/poetry" element={<Poetry />} />
              <Route path="/artwork" element={<Artwork />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/samples" element={<Samples />} />
              <Route path="/about" element={<About />} />                           
            </Routes>
          </div>       
          {!isDjsetsRendering && !isVideosRendering && <Footer />}
        </div>      
  );
}

export default App;
