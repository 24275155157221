import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    Link,
    MenuItem,
    Fab,
  } from "@mui/material";
  import './components/styles.scss';
  import MenuIcon from "@mui/icons-material/Menu";
  import React, { useState, useEffect } from "react";
  import { Link as RouterLink } from "react-router-dom";
  import HideOnScroll from "./components/HideOnScroll"
  import BackToTop from "./components/BackToTop";
  import { KeyboardArrowUp } from "@mui/icons-material";
  
  const headersData = [
    {
      label: "Home",
      href: "/home",
    },
    {
      label: "Meditate",
      href: "/meditate",
    },
    {
      label: "Music",
      href: "/music",
    },
    {
      label: "Videos",
      href: "/videos",
    },
    {
      label: "StagePlays",
      href: "/stageplays",
    },
    {
      label: "Musicals",
      href: "/musicals",
    },
    {
      label: "Books",
      href: "/books",
    },
    {
      label: "Poetry",
      href: "/poetry",
    },
    {
      label: "Artwork",
      href: "/artwork",
    },
    {
      label: "Blog",
      href: "/blog",
    },
    {
      label: "Shop",
      href: "/shop",
    },
    {
      label: "Samples",
      href: "/samples",
    },
    {
      label: "About",
      href: "/about",
    },
  ];

  
   
  export default function Navbar() {
  
    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
    });
  
    const { mobileView, drawerOpen } = state;
  
    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener("resize", () => setResponsiveness());
    }, []);
  
    const displayDesktop = () => {
      return (
        <Toolbar className='toolbar'>
          {mdkbLogo}
          <div>{getMenuButtons()}</div>
        </Toolbar>
      );
    };
  
    const displayMobile = () => {
      const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
      const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
  
      return (
        <Toolbar>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
            size="large">
            <MenuIcon />
          </IconButton>
  
          <Drawer
            {...{
              classes: {paper: 'paper'}, // MBEDIT - overrides the MUI paper item with my styles.scss
              anchor: "left",
              open: drawerOpen,
              onClick: handleDrawerClose,
              onClose: handleDrawerClose,
            }}
          >
            <div className='drawerContainer'>{getDrawerChoices()}</div>
          </Drawer>
  
          <div>{mdkbLogo}</div>
        </Toolbar>
      );
    };
  
    const getDrawerChoices = () => {
      return headersData.map(({ label, href }) => {
        return (
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
            }}
          >
            <MenuItem className='drawerMenu'>{label}</MenuItem>
          </Link>
        );
      });
    };
  
    const mdkbLogo = (
      <Typography variant="h6" component="h1" className='logo'>
        MarkDKBerry
      </Typography>
    );
  
    const getMenuButtons = () => {
      return headersData.map(({ label, href }) => {
        return (
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: 'menuButton',
            }}
          >
            {label}
          </Button>
        );
      });
    };
  
    return (
      <header>
        <HideOnScroll>
          <AppBar className='header' position="fixed">
            {mobileView ? displayMobile() : displayDesktop()}
          </AppBar>
        </HideOnScroll>
        <Toolbar id="back-to-top-anchor" />
        <BackToTop>
          <Fab color='primary' size="medium" aria-label="scroll back to top">
            <KeyboardArrowUp />
          </Fab>
        </BackToTop>
      </header>
    );
  }