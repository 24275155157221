import React from "react";
import '../../src/components/styles.scss';

export default function Music () {
  return (
    <div className='iframe-container'>
      <iframe 
        title="Learn Meditation Now" 
        name="Framename" 
        src="https://seo.markdkberry.com/sign-up" 
        height='100%' //mbedit was "height="640"" but its not filling the page well in browser.
        width='100%'
        frameBorder="0" 
        scrolling="yes" 
        >   
      </iframe>
    </div>
  );
};