import React from "react";
import '../../src/components/styles.scss';

/*
# not working for fourthwall online store, but keep in case I can find a way to make it work later:
export default function Shop () {
    return (
      <div className='iframe-container'>
        <iframe 
          title="Fourthwall Online Store" 
          name="Framename" 
          src="https://markdkberry-shop.fourthwall.com/en-aud/" 
          height='100%' //mbedit was "height="640"" but its not filling the page well in browser.
          width='100%'
          frameBorder="0" 
          scrolling="yes" 
          >   
        </iframe>
      </div>
    );
  };
*/

export default function Shop() {
  return (
    <div className="shop-container">
      <p>Visit my store:</p>
      <a 
        href="https://markdkberry-shop.fourthwall.com" 
        target="_blank" 
        rel="noopener noreferrer"
        className="shop-link"
      >
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/fourthwall-screenshot.jpg`} 
          alt="Fourthwall Store Front" 
          className="store-screenshot"
        />
      </a>
    </div>
  );
};

