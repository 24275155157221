import React from "react";
import '../../src/components/styles.scss';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from '@mui/material/Typography';



export default function About () {
  return (
    <div>
      <Grid container className='aboutbody'>
        <Grid item>
          <CardMedia 
            className='topmdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
          <Grid item>
          <Typography variant="h4" className='aboutTitle'>
          MUSICALS
          </Typography> 
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
            <i>For librettos, scripts, sheet music, midi files, or to discuss licensing options for production of musicals or stage plays, email: <b><a href="mailto:mark@markdkberry.com" target="_blank" rel="noopener noreferrer">mark@markdkberry.com</a></b></i>   
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          Black Magic (The Musical)
          </Typography>
            <Grid item>          
            <CardMedia 
                className='stageplay340'
                image={`${process.env.PUBLIC_URL}/assets/images/blackmagic-cover-1-640.jpg`}
            /> 
            </Grid>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
          Summary: <i>"A modern musical love story. A forty-something alcoholic de-frocked priest and a similarly aged escort navigate tough times in Soho, London, forming an unlikely connection defined by cash transactions but complicated by growing emotions. Their bond frays under the weight of feelings they didn’t expect. Can they overcome their lifestyles and make it work, or is their chance at love doomed to fall apart?"</i><br></br><br></br>
          1. Listen to the full soundtrack album here <b><a href="https://markdkberry.bandcamp.com/album/black-magic-the-musical" target="_blank" rel="noopener noreferrer">"Black Magic: The Musical (Soundtrack Album)"</a></b><br></br>
          2. The Script is available via the Shop menu above or on Amazon as ePub here <b><a href="https://www.amazon.com/Black-Magic-Mark-DK-Berry-ebook/dp/B0DPF64CPC" target="_blank" rel="noopener noreferrer">"Black Magic: The Musical (Script and Lyrics)"</a></b><br></br>
          3. Full Sheet music for all songs is available as A4 PDF downloads here <b><a href="https://markdkberry-shop.fourthwall.com/en-aud/products/black-magic-the-musical-full-score-and-all-instrument-parts-pdf" target="_blank" rel="noopener noreferrer">"Black Magic: The Musical (Full Score & Instrumentation in PDF)"</a></b>
          <br></br><br></br>- A love story musical in three acts.<br></br>- Length: est 1.5 to 2 hours.<br></br>- Comprises 10 songs and dialogue (download or stream soundtrack "Black Magic: The Musical" on bandcamp)<br></br>- Adult Themes. Some bad language. Sexual themes implied but not explicit. <br></br>- Roles for x1 male, x1 female in lead roles. x3 males, x3 females supporting roles. Small ensemble cast can expand as required. <br></br>- Musicians: The score can be performed acoustically with guitar and vocals, or expanded to include a small band with minimal orchestration (e.g., violin, cello, trumpet) or synthesised arrangements.
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          City of Lights (The Musical)
          </Typography>
            <Grid item>          
            <CardMedia 
                className='stageplay340'
                image={`${process.env.PUBLIC_URL}/assets/images/CityOfLights-cover-1-640small.jpg`}
            />
            </Grid>
          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>          
          - A musical war drama.<br></br>- Length: 1.5 to 2 hours (Five Acts).<br></br>- Comprises 10 songs and dialogue (see music menu for "City of Lights", songs have different order than the original album and musical changes to suit the stage version.)<br></br>- Features violence. No bad language or explicit sexual content. <br></br>- Cast: 5 main, 4 supporting (optional), small ensemble of extras.<br></br><br></br>- Summary: "Set in the 1940s, following a young man from London who is called to serve in World War II. Dropped into the chaos of the European frontline, he is quickly captured and sent to a PoW camp, where he faces harsh conditions but finds unexpected alliances. With the help of a fellow soldier and a courageous French nurse, he embarks on a daring escape through war-torn France. As he navigates the dangers of war and the bonds of friendship, he is forever changed by the experiences that shape his journey. Years later, the story reflects on the lasting impact of these events as peace returns, revealing a life transformed by both the horrors of war and the power of human connection. "City of Lights" is a story of survival, love, and the indelible scars left by conflict. However, the narrative carries a subtle, intriguing twist — hinting that the reality of his return may not be as straightforward as it seems. This touch of mystery adds depth to a story that resonates with the enduring spirit and resilience of those who faced the trials of war."     
          </Typography> 

          <Typography variant="h3" className='aboutTitle'>
          Musicals In Progress
          </Typography>

          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>          
          <b>Eurydice</b> - The story of Eurydice and Orpheus but seen from the view point of Eurydice and with a modern twist. The music is neo-classical style, and the original album of the same name can be heard via the "Music" menu. The music is written, but yet to be scored and adapted for stage. Currently 5 songs and more are in progress to complete the stage version. <br></br><br></br>
          <b>The Other Side</b> - The shamans journey to learn to connect to the other side. The original music for this can be heard via the "Music" menu but has yet to be scored and adapted for stage. Currently 8 songs and more are in progress to complete the stage version.<br></br><br></br>
          <b>Songs To Swing The Gallows</b> - A Western as a musical/stage play. A young man kills an important figure in a small town and is forced to go on the run into the desert. The original music can be heard via the "Music" menu, but is yet to be scored and adapted for stage. Comprises 9 songs and dialogue.<br></br><br></br>
          <b>The Search</b> - is the male "coming of age" story. Set in in Bristol in the 90s. A failed musician faces his demons and must figure out how change or pay the price. The music is not available at this time, though the songs are written and the script is hashed out, they are not yet properly recorded or scored. Comprises 14 songs.<br></br><br></br> 
          </Typography> 

          <Typography variant="body1" className='aboutText' style={{ wordWrap: "break-word" }}>
            <i>For scripts, librettos, sheet music, or to discuss options for musicals email <b><a href="mailto:mark@markdkberry.com" target="_blank" rel="noopener noreferrer">mark@markdkberry.com</a></b></i>   
          </Typography> 
        </Grid>
        <Grid item>          
          <CardMedia 
            className='bottommdkb'
            image={`${process.env.PUBLIC_URL}/assets/images/markberry-circle-alpha.png`}
          />
        </Grid>
      </Grid>  
    </div>
  )
}
