import React from "react";
import '../../src/components/styles.scss';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";


export default function Home () {


  return (
    <div className='home'>
      <Grid className='hero' container>
        {/* Top Of Page Product Placement */}
          <Grid item><div className='maintxt'>Click to listen to...</div></Grid>          
          <Grid item><img className='mainimg' title='Stream or download this track on bandcamp' src={`https://f4.bcbits.com/img/a2724759071_10.jpg`} alt="Latest Release" onClick={() => window.open('https://markdkberry.bandcamp.com/track/under-the-water', '_blank')} /></Grid>
      </Grid>
      {/*Advertising Cards */}
      <Grid container           
          justifyContent="space-between"
          alignItems="center"
        >
        <Grid item className='adgrid'> 
          <Card className='adcard'>
            <CardMedia
              className='admedia'
              image={`${process.env.PUBLIC_URL}/assets/images/bandcamp-ad.png`}
              title='MarkDKBerry Music on Bandcamp'
              onClick={() => window.open('https://markdkberry.bandcamp.com/', '_blank')}
            />
          </Card>
        </Grid>
        <Grid item className='adgrid'>
          <Card className='adcard'>
            <CardMedia
              className='admedia'
              image={`${process.env.PUBLIC_URL}/assets/images/rumble-ad.png`}
              title='MarkDKBerry Music Videos On Rumble'
              onClick={() => window.open('https://rumble.com/c/c-1331684', '_blank')}
            />
          </Card>
        </Grid>
        <Grid item className='adgrid'>
          <Card className='adcard'>
            <CardMedia
              className='admedia'
              image={`${process.env.PUBLIC_URL}/assets/images/goodreads-ad.png`}
              title='MarkDKBerry Books On Goodreads'
              onClick={() => window.open('https://www.goodreads.com/author/show/18558458.Mark_D_K_Berry', '_blank')}
            />
          </Card>          
        </Grid>
        <Grid item className='adgrid'>
          <Card className='adcard'>
            <CardMedia
              className='admedia'
              image={`${process.env.PUBLIC_URL}/assets/images/fourthwall-ad.png`}
              title='MarkDKBerry Shop on FourthWall'
              onClick={() => window.open('https://markdkberry-shop.fourthwall.com', '_blank')}
            />
          </Card>          
        </Grid>
      </Grid>
    </div>
  );
}